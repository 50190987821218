@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply box-border;
}

html {
  @apply !bg-primary;
  @apply scroll-smooth;
}

html,
body {
  @apply p-0 m-0 min-h-screen bg-white;
}

.prevent-scroll body {
  @apply !overflow-hidden;
}

#__next {
  @apply min-h-screen flex flex-col relative;
}

#__next > div {
  @apply font-text text-dark;
  @apply antialiased;
  @apply flex-1 flex flex-col;
}

main {
  @apply flex-1 min-h-full flex flex-col;
}

.content {
  @apply max-w-[1340px] mx-auto px-6 sm:px-7 md:px-8;
}

.card {
  @apply bg-gray-50 rounded-lg;
}

.h0 {
  @apply text-[40px] md:text-6xl font-title font-normal !leading-tight;
}

.h1 {
  @apply text-4xl md:text-5xl font-title font-medium !leading-tight;
}

.h2 {
  @apply text-[34px] md:text-[40px] font-title leading-tight;
}

.h3 {
  @apply text-2xl md:text-[32px] font-title font-normal leading-snug;
}

.h4 {
  @apply text-2xl font-title font-normal leading-snug;
}

.h5 {
  @apply text-lg md:text-[22px] font-title font-normal leading-snug;
}

.h6 {
  @apply text-lg font-title font-normal leading-snug;
}

/* Headings */

p {
  @apply text-[17px] font-text font-normal leading-[1.8];
  @apply whitespace-pre-line;
}

.p-lg {
  @apply md:text-lg;
}

/* Markdown */
.markdown {
  /* @apply flex flex-col gap-4; */
  @apply flex flex-col gap-2;
}

.markdown a {
  @apply text-primary !underline font-semibold;
}

.markdown ul {
  @apply list-disc pl-6 marker:text-primary-400 space-y-4;
}

.markdown ol {
  @apply list-decimal pl-6;
}

.markdown li {
  @apply mb-4 text-[17px];
}

.markdown a {
  @apply text-primary font-semibold whitespace-nowrap no-underline;
}

.markdown h1,
.markdown h2 {
  @apply font-title font-medium text-3xl mt-6 mb-2;
}

.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  @apply font-title font-medium text-2xl mt-4 mb-1;
}

.markdown p img {
  @apply my-6 h-[280px] md:h-[360px] lg:h-[440px] w-full object-cover rounded-lg;
}

.markdown p {
  @apply leading-[1.8];
  /* @apply mb-3; */
}

/* Inputs */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: black;
}

input,
select,
textarea {
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;
}

.gray-section-background {
  border-image-source: linear-gradient(#f7f7f7, #f7f7f7);
  border-image-outset: 0 100vw 0 100vw;
  border-image-slice: 0 fill;
}

/* Map */
.gm-style-iw {
  @apply !overflow-auto;
}

.gm-style-iw .gm-style-iw-ch {
  @apply hidden;
}

.gm-style-iw .gm-style-iw-chr {
  @apply absolute top-0 right-0;
}

.gm-style-iw .gm-style-iw-d {
  @apply pt-4 pr-8;
}

/* Loading Spinner */
.loading-spinner {
  --uib-size: 24px;
  --uib-color: currentColor;
  --uib-speed: 1s;
  --uib-stroke: 3px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.line {
  position: absolute;
  top: 0;
  left: calc(50% - var(--uib-stroke) / 2);
  display: flex;
  align-items: flex-start;
  height: 100%;
  width: var(--uib-stroke);
}

.line::before {
  content: "";
  height: 22%;
  width: 100%;
  border-radius: calc(var(--uib-stroke) / 2);
  /* background-color: var(--uib-color); */
  background-color: currentColor;
  animation: pulse calc(var(--uib-speed)) ease-in-out infinite;
  transition: background-color 0.3s ease;
  transform-origin: center bottom;
}

.line:nth-child(1) {
  transform: rotate(calc(360deg / -12 * 1));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 1);
  }
}
.line:nth-child(2) {
  transform: rotate(calc(360deg / -12 * 2));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 2);
  }
}
.line:nth-child(3) {
  transform: rotate(calc(360deg / -12 * 3));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 3);
  }
}
.line:nth-child(4) {
  transform: rotate(calc(360deg / -12 * 4));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 4);
  }
}
.line:nth-child(5) {
  transform: rotate(calc(360deg / -12 * 5));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 5);
  }
}
.line:nth-child(6) {
  transform: rotate(calc(360deg / -12 * 6));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 6);
  }
}
.line:nth-child(7) {
  transform: rotate(calc(360deg / -12 * 7));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 7);
  }
}
.line:nth-child(8) {
  transform: rotate(calc(360deg / -12 * 8));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 8);
  }
}
.line:nth-child(9) {
  transform: rotate(calc(360deg / -12 * 9));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 9);
  }
}
.line:nth-child(10) {
  transform: rotate(calc(360deg / -12 * 10));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 10);
  }
}
.line:nth-child(11) {
  transform: rotate(calc(360deg / -12 * 11));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 11);
  }
}

@keyframes pulse {
  0%,
  80%,
  100% {
    transform: scaleY(0.75);
    opacity: 0;
  }
  20% {
    transform: scaleY(1);
    opacity: 1;
  }
}
